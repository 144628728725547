<template>
  <div>
    <table class="flex flex-col">
      <thead class="flex flex-col">
        <tr class="flex overflow-x-auto">
          <th class="th-row flex-none w-20 h-12 text-sm text-center">Fecha de Creación</th>
          <th class="th-row flex-none w-16 h-12 text-sm text-center">GV</th>
          <th class="th-row flex-none w-24 h-12 text-sm text-center">Edentificación</th>
          <th class="th-row flex-none w-48 h-12 text-sm text-center">Cliente</th>
          <th class="th-row flex-none w-16 h-12 text-sm text-center">GVT</th>
          <th class="th-row flex-none w-56 h-12 text-sm text-center">Descripción</th>
          <th class="th-row flex-none w-16 h-12 text-sm text-center">Valor</th>
          <th class="th-row flex-none w-16 h-12 text-sm text-center">Flete</th>
          <th class="th-row flex-none w-16 h-12 text-sm text-center">Seguro</th>
          <th class="th-row flex-none w-16 h-12 text-sm text-center">Total (CIF)</th>
          <th class="th-row flex-none w-32 h-12 text-sm text-center">Estado Paquete</th>
          <th class="th-row flex-none w-32 h-12 text-sm text-center">Estado Exención</th>
          <th class="th-row flex-none w-44 h-12 text-sm text-center">Acciones</th>
        </tr>
      </thead>
      <tbody class="flex flex-col">
        <template class="w-full my-4 flex flex-col" v-for="(item) in packages">
          <tr class="flex my-2 text-center overflow-x-auto" v-bind:key="item.id" >
            <td class="td-row flex-none w-20 h-20 text-sm text-center">{{item.date}}</td>
            <td class="td-row flex-none w-16 h-20 text-sm text-center">{{item.gv}}</td>
            <td class="td-row flex-none w-24 h-20 text-sm text-center">{{item.id}}</td>
            <td class="td-row flex-none w-48 text-sm text-center">{{item.client_name}}</td>
            <td class="td-row flex-none w-16 h-20 text-sm text-center">{{item.gvt}}</td>
            <td class="td-row flex-none w-56 h-20 text-sm text-center overflow-auto">
              {{item.description}}
            </td>
            <td class="td-row flex-none w-16 h-20 text-sm text-center">{{item.value}}</td>
            <td class="td-row flex-none w-16 h-20 text-sm text-center">{{item.freight}}</td>
            <td class="td-row flex-none w-16 h-20 text-sm text-center">{{item.security}}</td>
            <td class="td-row flex-none w-16 h-20 text-sm text-center">{{item.total}}</td>
            <td class="td-row flex-none w-32 h-20 text-sm text-center">{{item.status}}</td>
            <td class="td-row flex-none w-32 h-20 text-sm text-center">
              {{item.exemption_status}}
            </td>
            <td class="td-row flex-none w-44 h-20 text-sm text-center">
              <button
                class="check">Revisión documentos
              </button>
              <button
                class="delete my-2">Denegar
              </button>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>

const css = {
  table: {
    tableClass: 'table-auto w-full',
    tableBodyClass: '',
    tableHeaderClass: 'px-4 py-2',
    tableWrapper: '',
    loadingClass: 'loading',
    ascendingIcon: 'blue chevron up icon',
    descendingIcon: 'blue chevron down icon',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: 'grey sort icon',
    handleIcon: 'grey sidebar icon',
  },
};

export default {
  name: 'TableExemption',
  props: {
    css: {
      type: Object,
      default() {
        return css;
      },
    },
    packages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    };
  },
};
</script>
<style scoped>
  .th-row {
    @apply self-center;
    background-color: var(--light-gray);
    padding: 0.5rem 0 !important;
  }
  .td-row {
    @apply border-t border-gray-200 text-sm;
    padding: 0.5rem 0 !important;
  }
  .td-row:first-child {
    @apply border-t border-white text-sm;
    padding: 0.5rem 0 !important;
  }
  .check {
    @apply text-xs bg-blue-800 px-2 py-1 text-white rounded-full;
  }
  .delete {
    @apply text-xs bg-red-600 text-white px-2 py-1 rounded-full;
  }
</style>
