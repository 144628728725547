<template>
  <div class="w-full flex flex-col">
    <bread-crumbs class="flex justify-end text-xs" :items="routes" />
    <h3 class="text-start mt-4 text-2xl mb-2">Exenciones</h3>
     <h4 class="text-start mt-1  text-gray-500 text-lg">Listado</h4>
    <section class="w-full bg-white p-2 ">
      <table-exemption :packages="packages" />
    </section>
  </div>
</template>
<script>
import TableExemption from '../components/TableExemption.vue';
import BreadCrumbs from '@/components/BreadCrumbs.vue';

export default {
  name: 'Package',
  components: {
    TableExemption,
    BreadCrumbs,
  },
  data() {
    return {
      isToggle: false,
      filterForm: {
        keyWord: '',
        filterBySelect: null,
        operatorSelect: null,
        client: null,
        agent: null,
        state: '',
        payment: '',
        sortBy: null,
        office: null,
      },
      routes: [
        { name: 'Inicio', path: '/' },
      ],
      packages: [
        {
          date: '2017-10-02',
          gv: 'GV9616',
          id: '401710221',
          client_name: 'Dionisio Arias Acosta',
          gvt: 'GVT60990',
          description: '8258/8278 car radio 1 din bluetooth',
          value: '$20.50',
          freight: '$6.00',
          security: '$1.00',
          total: '$27.50',
          status: 'En Espera',
          exemption_status: 'common.documents',
        },
        {
          date: '2017-10-15',
          gv: 'GV13540',
          id: '701410381',
          client_name: 'Tyronne Molina',
          gvt: 'GVT61918',
          description: 'New Cute Holder Two Hands Adjustable Stand for Mobile Phone',
          value: '$0.00',
          freight: '$6.00',
          security: '$0.00',
          total: '$6.00',
          status: 'En Espera',
          exemption_status: 'Solicitada',
        },
        {
          date: '2017-10-16',
          gv: 'GV11404',
          id: '401650370',
          client_name: 'Rossana Canessa Ulloa',
          gvt: 'GVT62005',
          description: 'Vestido',
          value: '$17.00',
          freight: '$6.00',
          security: '$1.00',
          total: '$24.00',
          status: 'En Espera',
          exemption_status: 'Solicitada',
        },
      ],
    };
  },
  methods: {
    handleToggle() {
      this.isToggle = !this.isToggle;
    },
    applyFilters() {
      // Logic here
    },
    setCategoryValue() {
      //
    },
    loadRoutesBreadCrumbs(router) {
      const {
        history: { _startLocation, current, router: { options: { routes } } },
      } = router;
      const startRoute = routes.find((route) => route.path === _startLocation);
      this.routes.push(startRoute);
      if (startRoute.name !== current.name) this.routes.push(current);
    },
  },
  mounted() {
    this.loadRoutesBreadCrumbs(this.$router);
  },
};
</script>
<style scoped>
  .page-wrap {
    overflow-x: hidden;
    font-family: 'Helvetica Neue', sans-serif;
    line-height: 26px;
  }
  .page-content {
    width: 80%;
    position: relative;
    z-index: 0;
    padding: 10px 15px;
    transition: +0.6s;
    transform: translateX(320px);
  }
</style>
